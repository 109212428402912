import { FigureType, ISelectWork, ISelectWorkComponent } from "../libs/types";
import { getFileUrl, getImgUrl } from "../libs/sanity.ts";
import { PortableText } from "@portabletext/react";
import { portableTextComponents } from "./PortableTextComponents.tsx";
import React from "react";

export const SelectWorks = ({ works }: ISelectWorkComponent) => {
	return (
		<div className="module fade-in select-work">
			<p className="italic">Select Work</p>
			{works.map((data) => (
				<Row work={data} key={data._id} />
			))}
		</div>
	);
};

const Row = ({ work }: { work: ISelectWork }) => {
	const { workTitle, mediaList, workDescription } = work;

	return (
		<div className="split">
			<div className="left">
				<p>{workTitle}</p>
				<PortableText
					value={workDescription}
					components={portableTextComponents}
				/>
			</div>
			<div className="right">
				{mediaList.map((asset, i) => {
					switch (asset._type) {
						case "figure":
							return <Image asset={asset} key={i} />;
						case "videoFile":
							return <VideoFile asset={asset} key={i} />;
						case "videoEmbed":
							return <VideoEmbed asset={asset as any} key={i} />;
						default:
							return <React.Fragment />;
					}
				})}
			</div>
		</div>
	);
};

const Image = ({ asset }: { asset: FigureType }) => {
	if (!asset.asset) {
		return <React.Fragment />;
	}
	return (
		<div
			key={asset._key}
			className="asset img"
			style={{
				backgroundImage: `url(${getImgUrl(asset.asset)})`,
			}}
		/>
	);
};

const VideoFile = ({ asset }: { asset: FigureType }) => {
	if (!asset.asset) {
		return <React.Fragment />;
	}

	return (
		<video
			key={asset._key}
			className="asset video"
			src={getFileUrl(asset.asset)}
			playsInline
			controls
			autoPlay
			loop
			muted
		/>
	);
};

const VideoEmbed = ({ asset }: { asset: { title: string; id: string } }) => {
	console.log(asset);
	if (!asset.id) {
		return <React.Fragment />;
	}

	return (
		<div className="asset video-embed">
			<iframe
				src={`https://player.vimeo.com/video/${asset.id}?badge=0&autopause=0&loop=1&autoplay=1&muted=1&app_id=58479&player_id=0`}
				frameBorder="0"
				allow="autoplay; fullscreen;"
				style={{
					width: "100%",
					height: "100%",
				}}
				title={asset.title ?? "Video"}
			/>
		</div>
	);
};
