import { createClient } from "@sanity/client";

export const SANITY_PROJECT_ID = "3ti0i8bf";

export const SANITY_DATASET = "production";

export const SANITY_CLIENT_API_VERSION = "2023-07-04";

export default createClient({
	projectId: SANITY_PROJECT_ID,
	dataset: SANITY_DATASET,
	apiVersion: SANITY_CLIENT_API_VERSION,
});
