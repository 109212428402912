import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home.tsx";
import Dev from "./components/Dev.tsx";

const routes = [
	{ path: "/", name: "Home", Component: Home },
	{ path: "/dev", name: "Dev", Component: Dev },
	{ path: "*", name: "Wildcard", Component: Home },
];

export function App() {
	return (
		<BrowserRouter>
			<CustomRoutes />
		</BrowserRouter>
	);
}

const CustomRoutes = () => {
	const location = useLocation();
	return (
		<Routes location={location} key={location.pathname}>
			{routes.map(({ path, Component, name }) => (
				<Route key={name} path={path} element={<Component />} />
			))}
		</Routes>
	);
};
